import { InputField } from "component/Form";
import React, { useEffect, useRef, useState } from "react";
import InvoiceGenerateTable from "./InvoiceGenerateTable";
import SignatureCanvas from "react-signature-canvas";
import { base64ToBlob, formatDateToYYYYMMDD } from "helpers/helper";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "sonner";
import {
  useCreateInvoiceMutation,
  useGetInvoiceByIdQuery,
  useUpdateInvoiceMutation,
} from "../../redux/services/invoiceApi";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { InvoiceItem, ScannedItem, invoiceGenerateData } from "./utils";
import { Invoices } from "../../../@types/invoice";
import { useAppSelector } from "../../redux/store";
import { selectUser } from "../../redux/features/authSlice";
import { SingleUser } from "../../../@types/auth";
import { IDetectedBarcode, Scanner } from "@yudiel/react-qr-scanner";
import { useDispatch } from "react-redux";
import {
  selectAmount,
  setOtherScannedItems,
  setScannedInvoice,
} from "../../redux/features/scannedItemsSlice";
import { SelectField } from "component/Select";
import { OptionType } from "pages/Dashboard/types";
import { useSidebar } from "component/Sidebar/useSidebar";
import { BtnLoader } from "component/Loader/Loader";

const AddInvoiceModal = ({
  closeModal,
  onOpenTemplateModal,
  isEdit,
  data,
  invoiceID,
}: {
  closeModal: () => void;
  onOpenTemplateModal: () => void;
  isEdit?: boolean;
  data: Invoices;
  invoiceID: number;
}) => {
  const parseInvoiceBreakdown = (data: Invoices) => {
    const breakdown =
      typeof data?.invoices_breakdown === "string"
        ? JSON.parse(data?.invoices_breakdown)
        : data?.invoices_breakdown;
    return breakdown;
  };

  const [scannedItems, setScannedItems] = useState<ScannedItem[]>([]);

  const handleScan = (result: IDetectedBarcode[]) => {
    result.forEach((barcode) => {
      const parsedData = JSON.parse(barcode.rawValue) as ScannedItem;
      setScannedItems((prevItems) => [...prevItems, parsedData]);
    });
  };

  const statusOption = [
    {
      label: "Delivered",
      value: "delivered",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Returned",
      value: "returned",
    },
  ];

  const validationSchema = yup.object().shape({
    invoice_to: yup.string().required("Invoice to field is required"),
    delivered_to: yup.string().required("Delivered to field is required"),
    invoice_date: yup.string().required("Invoice date field is required"),
    invoice_no: yup.string().required("Invoice no field is required"),
    address: yup.string().required("Address field is required"),
    payment_method: yup.string().required("Payment method field is required"),
    status: yup.object().shape({
      value: yup.string().required("Status is required"),
    }),
  });

  const formValues = {
    invoice_to: (isEdit && data?.invoice_to) || "",
    delivered_to: (isEdit && data?.delivered_to) || "",
    invoice_date: (isEdit && data?.invoice_date) || "",
    invoice_no: (isEdit && data?.invoice_no) || "",
    address: (isEdit && data?.address) || "",
    entry_officer: (isEdit && data?.entry_officer) || "",
    payment_method: (isEdit && data?.payment_method) || "",
    signatory: "",
    status: (isEdit && {
      label: data?.status,
      value: data?.status,
    }) || {
      label: "Select status...",
      value: "",
    },
  };

  // space

  const [createInvoice, { isLoading }] = useCreateInvoiceMutation();
  const [updateInvoice, { isLoading: isLoadingUpdate }] =
    useUpdateInvoiceMutation();

  const [signatureURL, setSignatureURL] = useState<any>(null);
  const [preview, setPreview] = useState<string | undefined>("");
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
  } = useGetInvoiceByIdQuery(invoiceID, { skip: !invoiceID });

  const clearSignature = () => {
    // sigCanvas.current?.clear();
    // setSignatureURL(null);
    if (sigCanvas.current) {
      sigCanvas.current.clear(); // Clear the canvas
      setIsDisabled(false); // Enable signing after clearing the signature
    }
  };

  const saveSignature = () => {
    if (sigCanvas.current) {
      const signatureDataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");

      setPreview(signatureDataURL);
      const base64Data = signatureDataURL.replace(
        /^data:image\/png;base64,/,
        ""
      );
      const binaryData = atob(base64Data);

      const arrayBuffer = new Uint8Array(binaryData.length);

      for (let i = 0; i < binaryData.length; i++) {
        arrayBuffer[i] = binaryData.charCodeAt(i);
      }

      const file = new File([arrayBuffer], "signature.png", {
        type: "image/png",
      });

      setSignatureURL(file);
    }
  };

  const dispatch = useDispatch();
  const amountDetails = useAppSelector(selectAmount);
  const handleSubmit = async () => {
    const formData = new FormData();

    if (signatureURL) {
      formData.append("signatory", signatureURL);
    } else if (preview) {
      const imageBlob = base64ToBlob(preview);
      formData.append("signatory", imageBlob, "signature.png");
    } else if (isEditSignature && invoicesData?.invoice?.signatory) {
      formData.append("signatory", invoicesData?.invoice?.signatory);
    }
    // if (signatureURL) {
    //   formData.append("signatory", signatureURL);
    // } else if (preview) {
    //   const imageBlob = base64ToBlob(preview);
    //   formData.append("signatory", imageBlob, "signature.png");
    // } else if (isEditSignature && invoicesData?.invoice?.signatory) {
    //   formData.append("signatory", invoicesData?.invoice?.signatory);
    // } else {
    //   formData.append("signatory", signatureURL);
    // }
    // console.log(signatureURL, "signature url");
    formData.append("invoice_to", formik.values.invoice_to);
    formData.append("delivered_to", formik.values.delivered_to);
    formData.append("invoice_date", formik.values.invoice_date);
    formData.append("invoice_no", formik.values.invoice_no);
    formData.append("address", formik.values.address);
    formData.append("payment_method", formik.values.payment_method);
    formData.append("status", formik.values.status.value);
    formData.append("amount", String(amountDetails?.total_amount));
    formData.append("discount", String(amountDetails?.discount_amount));

    const invoiceDetailsJson = JSON.stringify(scannedItems);
    formData.append("invoices_breakdown", invoiceDetailsJson);

    dispatch(setScannedInvoice(scannedItems));
    if (isEdit) {
      await updateInvoice({ id: data?.id, payload: formData })
        .unwrap()
        .then((payload) => {
          dispatch(
            setOtherScannedItems({
              invoice_date: formik.values.invoice_date,
              delivered_to: formik.values.delivered_to,
              payment_method: formik.values.payment_method,
              invoice_to: formik.values.invoice_to,
              invoice_no: formik.values.invoice_no,
              entry_officer: `${(user as SingleUser)?.first_name} ${
                (user as SingleUser)?.last_name
              }`,
              customer: formik.values.delivered_to,
              address: formik.values.address,
              signature: payload?.data?.invoice?.signatory,
            })
          );
        });
      toast.success("Invoice Updated Successfully");
    } else {
      await createInvoice(formData)
        .unwrap()
        .then((payload) => {
          dispatch(
            setOtherScannedItems({
              invoice_date: formik.values.invoice_date,
              delivered_to: formik.values.delivered_to,
              payment_method: formik.values.payment_method,
              invoice_to: formik.values.invoice_to,
              invoice_no: formik.values.invoice_no,
              entry_officer: `${(user as SingleUser)?.first_name} ${
                (user as SingleUser)?.last_name
              }`,
              customer: formik.values.delivered_to,
              address: formik.values.address,
              signature: payload?.data?.purchase?.signatory,
            })
          );
        });
      toast.success("Invoice Created Successfully");
    }

    setTimeout(() => {
      toast.dismiss();
      closeModal();
      onOpenTemplateModal();
    }, 1000);
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const labelClass = "text-[14px] font-medium";
  const inputClass = "pl-3 py-3";
  const sigCanvas = useRef<SignatureCanvas | null>(null);

  const user = useAppSelector(selectUser);

  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "!bg-[#222121]" : "!bg-[#f0f0f0]";
  const toggledDateTheme = isDarkTheme ? "bg-[#222121]" : "";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const btnSignNotDisable =
    isEdit && sigCanvas.current && sigCanvas.current?.isEmpty() ? false : true;

  const btnDisabled =
    isEdit && sigCanvas.current
      ? (!formik.isValid || !formik.dirty) && !btnSignNotDisable
      : !formik.isValid || !formik.dirty;

  const [isEditSignature, setIsEditSignature] = useState(isEdit);
  const clearEditSignature = () => {
    setIsEditSignature(false);
  };

  return (
    <form className="p-6 px-10 h-[800px] pb-10" onSubmit={formik.handleSubmit}>
      <p className={`${toggleTextTheme} transition-all font-medium text-xl`}>
        {isEdit ? "Update Invoice" : "Create Invoice"}
      </p>
      <div className="grid grid-cols-2 gap-x-10  gap-y-5 mt-5">
        <InputField
          type="text"
          label="Invoice to"
          name="invoice_to"
          value={formik.values.invoice_to}
          onChange={formik.handleChange}
          placeholder="Enter name"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Delivered to"
          name="delivered_to"
          value={formik.values.delivered_to}
          onChange={formik.handleChange}
          placeholder="Enter name"
          className={inputClass}
          labelClassName={labelClass}
        />
        <div className="flex flex-col">
          <label className={`${labelClass} ${toggleTextTheme} mb-1`}>
            Invoice Date
          </label>
          <Flatpickr
            value={formik.values.invoice_date}
            onChange={(selectedDates: Date[]) => {
              const [date] = selectedDates;
              formik.setFieldValue("invoice_date", formatDateToYYYYMMDD(date));
            }}
            placeholder="Enter invoice date"
            className={`${inputClass} ${toggleTextTheme}  ${toggledDateTheme} rounded-lg border  outline-none h-[50px] `}
          />
        </div>
        <InputField
          type="text"
          label="Invoice no"
          name="invoice_no"
          value={formik.values.invoice_no}
          onChange={formik.handleChange}
          placeholder="Enter number"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Address"
          name="address"
          value={formik.values.address}
          onChange={formik.handleChange}
          placeholder="Enter address"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Entry officer"
          name="entry_officer"
          value={
            (isEdit && data?.entry_officer) ||
            `${(user as SingleUser)?.first_name} ${
              (user as SingleUser)?.last_name
            }`
          }
          disabled
          onChange={formik.handleChange}
          placeholder="Enter name"
          className={`${inputClass} cursor-not-allowed ${toggledBgTheme} !text-[#ccc]`}
          labelClassName={labelClass}
        />

        <InputField
          type="text"
          label="Payment method"
          name="payment_method"
          value={formik.values.payment_method}
          onChange={formik.handleChange}
          placeholder="Enter payment method"
          className={inputClass}
          labelClassName={labelClass}
        />
        <div className="flex flex-col  -ml-3 ">
          <label className={`${labelClass} ${toggleTextTheme}  -mb-2 ml-3`}>
            Status
          </label>
          <SelectField
            value={formik.values.status as OptionType}
            selectOptions={statusOption}
            onChange={(e: any) => {
              formik.setFieldValue("status", e);
              formik.setFieldValue("status.value", e?.value);
            }}
            placeholder="Select status.."
            className={`${inputClass} !w-full`}
            padding="7px"
            bgColor={isDarkTheme ? "#222121" : "white"}
          />
        </div>
        <div>
          <label
            htmlFor="signatory"
            className={`text-sm ${toggleTextTheme} font-medium`}
          >
            Signatory{" "}
            {isEditSignature ? (
              <span className="text-yellow-200 text-xs ">
                (you have to clear signture to sign a new one)
              </span>
            ) : null}
          </label>

          {isEditSignature ? (
            <div className="border rounded-lg mt-1 cursor-not-allowed flex justify-center  h-[15rem]  w-full border-1 p-5">
              {isLoadingInvoices ? (
                <BtnLoader />
              ) : (
                <img
                  src={invoicesData?.invoice?.signatory}
                  alt="signatory"
                  className=""
                />
              )}
            </div>
          ) : (
            <SignatureCanvas
              ref={sigCanvas}
              penColor={"black"}
              canvasProps={{
                width: 500,
                height: 200,
                className: "border rounded-lg mt-1 border-1 p-1",
              }}
              onEnd={saveSignature}
            />
          )}

          {isEditSignature ? (
            <div className="flex gap-2 items-center mt-4">
              <p
                className="cursor-pointer font-medium text-sm text-red-500"
                onClick={clearEditSignature}
              >
                Clear Signature
              </p>
            </div>
          ) : (
            <div className="flex gap-2 items-center mt-4">
              <p
                className="cursor-pointer font-medium text-sm text-red-500"
                onClick={clearSignature}
              >
                Clear Signature
              </p>
            </div>
          )}
        </div>
        <div className="" style={{ height: "300px", width: "300px" }}>
          <Scanner onScan={handleScan} />
        </div>
      </div>
      <InvoiceGenerateTable
        isLoading={isLoading || isLoadingUpdate}
        disabled={btnDisabled}
        invoiceData={isEdit ? parseInvoiceBreakdown(data) : scannedItems}
        setInvoiceData={setScannedItems}
        isEdit={isEdit}
      />
    </form>
  );
};

export default AddInvoiceModal;
