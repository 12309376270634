import { Frame } from "component/Frame";
import DashboardModal from "component/Modal/Modal";
import useDisclosure from "hooks/useDisclosure";
import React, { useEffect, useState } from "react";
import AddUserInvite from "./AddUserInvite";
import { DeleteIcon, EditIcon } from "assets/images/svgs";
import {
  useDeleteInvitedUserMutation,
  useGetAllInvitedUsersQuery,
} from "../../redux/services/settingsApi";
import { formatDate } from "helpers/helper";
import Skeleton from "react-loading-skeleton";
import { SingleUser } from "../../../@types/auth";
import { toast } from "sonner";
import { useSidebar } from "component/Sidebar/useSidebar";

const AdminSetting = () => {
  const {
    isOpen: openInviteUserModal,
    open: onOpenInviteUserModal,
    close: closeInviteUserModal,
  } = useDisclosure();
  const {
    data: invitedUserData,
    isLoading,
    isFetching,
  } = useGetAllInvitedUsersQuery();

  const invitedUser = invitedUserData ?? [];
  const [userDetail, setUserDetail] = useState<SingleUser>();
  const [isEditUser, setIsEditUser] = useState(false);

  const [deleteInvitedUser] = useDeleteInvitedUserMutation();
  const handleDelete = async (delId: number) => {
    toast.loading("Deleting user...");
    const id = delId;
    await deleteInvitedUser({ id: id })
      .unwrap()
      .then(() => {
        toast.success("User Deleted Successfully");
        new Promise(() => {
          setTimeout(() => {
            toast.dismiss();
          }, 1000);
        });
      });
  };

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";
  return (
    <div className="flex flex-col gap-5">
      <Frame>
        <div>
          <p
            className={`${toggleTextTheme} font-medium text-base cursor-pointer`}
            onClick={onOpenInviteUserModal}
          >
            Add user via invite
          </p>
        </div>
      </Frame>
      <Frame rmPadding>
        <div>
          <p
            className={`border-b border-[#999898] p-5 ${toggleTextTheme} font-medium text-2xl`}
          >
            Users
          </p>
        </div>
        <div className=" p-5">
          <div className="flex flex-col gap-3 ">
            {isLoading || isFetching
              ? Array.from({ length: 5 }).map((_, idx) => (
                  <div key={idx} className="flex flex-col border-gray-300">
                    <Skeleton
                      baseColor="#E0E0E0"
                      highlightColor="#F5F5F5"
                      width={300}
                      height={20}
                    />
                    <Skeleton
                      baseColor="#E0E0E0"
                      highlightColor="#F5F5F5"
                      width={300}
                      height={10}
                    />
                  </div>
                ))
              : invitedUser.map((chi, idx) => {
                  const { first_name, last_name, created_at, id } = chi;
                  return (
                    <div
                      key={idx}
                      className="flex items-center justify-between"
                    >
                      <div className="flex flex-col">
                        <p
                          className={`${toggleTextTheme} font-medium text-base capitalize`}
                        >{`${first_name} ${last_name}`}</p>
                        <p className="text-[#999898] font-medium text-[11px]">
                          {formatDate(created_at)}
                        </p>
                      </div>
                      <div className="flex gap-5 items-center">
                        <EditIcon
                          className="cursor-pointer"
                          onClick={() => {
                            onOpenInviteUserModal();
                            setUserDetail(chi);
                            setIsEditUser(true);
                          }}
                        />
                        <DeleteIcon
                          className="cursor-pointer"
                          onClick={() => handleDelete(id)}
                        />
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </Frame>
      {/* modal wrap start */}
      <DashboardModal
        open={openInviteUserModal}
        onClose={() => {
          setIsEditUser(false);
          closeInviteUserModal();
        }}
        width={"492px"}
      >
        <AddUserInvite
          closeModal={() => {
            closeInviteUserModal();
            setIsEditUser(false);
          }}
          user={userDetail}
          editUser={isEditUser}
        />
      </DashboardModal>
      {/* modal wrap end */}
    </div>
  );
};

export default AdminSetting;
