import { generateQueryString } from "helpers/helper";
import { baseApi } from "../../redux/baseApi";
import { QueryParams } from "../../../@types/dashboard";


export const debitApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createDebit: builder.mutation({
      query: (payload) => ({
        url: "/debit/create-debit",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["debits"],
    }),

    getDebits: builder.query<any , QueryParams>({
      query: (params) => ({
        url: `/debit/get-debit${generateQueryString({ ...params })}`,
        method: "GET",
      }),
      providesTags: ["debits"],
      transformResponse: (response: any ) => response,
    }),

    updateDebit: builder.mutation<void, { id: number; payload: any }>({
      query: ({ id, payload }) => ({
        url: `debit/update-debit/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["debits"],
    }),

    deleteDebit: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `debit/delete-debit/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["debits"],
    }),

    downloadDebits: builder.mutation<Blob, void>({
      query: () => ({
        url: "sales/export/excel",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),

    exportDebits: builder.mutation<Blob, void>({
      query: () => ({
        url: "sales/export/pdf",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useCreateDebitMutation,
  useGetDebitsQuery,
  useUpdateDebitMutation,
  useDeleteDebitMutation,
  useDownloadDebitsMutation,
  useExportDebitsMutation,
} = debitApi;
