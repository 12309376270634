import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import React from "react";
import { useLogoutMutation } from "../../redux/services/authApi";
import { toast } from "sonner";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setAuthUser } from "../../redux/features/authSlice";
import { useSidebar } from "component/Sidebar/useSidebar";
import Cookies from "js-cookie";

const Logout = ({ onCancel }: { onCancel: () => void }) => {
  const [logout, { isLoading }] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isDarkTheme } = useSidebar();

  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";
  const toggledBgTheme = isDarkTheme ? "!bg-[#222121]" : "!bg-white";

  const clearToken = () => {
    Cookies.remove("token");
  };

  const handleLogout = async () => {
    await logout()
      .unwrap()
      .then(() => {
        // toast.success("Logged out Successfully");
        new Promise(() => {
          setTimeout(() => {
            toast.dismiss();
            localStorage.clear();
            sessionStorage.clear();
            clearToken();
            dispatch(
              setAuthUser({
                token: "",
                user: {
                  role: "",
                  active: 0,
                  created_at: "",
                  email: "",
                  id: 0,
                  invited_by: "",
                  notify_email: false,
                  notify_inapp: false,
                  notify_sms: false,
                  profile_picture: "",
                  updated_at: "",
                  username: "",
                },
              })
            );
            onCancel();
            navigate("/login");
            window.location.reload();
          }, 1000);
        });
      });
  };
  return (
    <div className="flex flex-col gap-2 p-5 ">
      <p className={`${toggleTextTheme} font-light text-center`}>
        Are you sure you want to logout ?
      </p>
      <div className="flex gap-3 items-center justify-end mt-10">
        <Button
          btnType={ButtonTypeEnum.Button}
          label="Yes, Logout"
          className="w-[200px] bg-[#DB2B2B] py-2"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleLogout}
        />
        <Button
          onClick={onCancel}
          btnType={ButtonTypeEnum.Button}
          label="Cancel"
          className={`w-[150px] py-2  !text-[#4A07A0] ${toggledBgTheme} border-[#4A07A0] border-[1px]`}
        />
      </div>
    </div>
  );
};

export default Logout;
