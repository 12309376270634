import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootStateType } from "../../redux/store";
import { Auth } from "../../../@types/auth";

const initialState: Auth = {
  token: "",
  user: {
    role: "",
    active: 0,
    created_at: "",
    email: "",
    id: 0,
    invited_by: "",
    notify_email: false,
    notify_inapp: false,
    notify_sms: false,
    profile_picture: "",
    updated_at: "",
    username: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<Auth>) => {
      state.user = action.payload.user;
      if (action.payload.token) {
        state.token = action.payload.token;
      }
    },
  },
});

export const { setAuthUser } = authSlice.actions;

export const authSliceReducer = authSlice.reducer;
export const selectUser = (state: RootStateType) => state.auth.user;
