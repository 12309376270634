import { Frame } from "component/Frame";
import { useSidebar } from "component/Sidebar/useSidebar";
import React from "react";

const AccountSettings = ({
  onProfileClick,
  onLoginHistoryClick,
}: {
  onProfileClick: () => void;
  onLoginHistoryClick: () => void;
}) => {
  const { isDarkTheme } = useSidebar();

  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";
  return (
    <Frame rmPadding>
      <div className="flex flex-col">
        <div
          className="flex flex-col p-2 cursor-pointer"
          onClick={onProfileClick}
        >
          <p className={`${toggleTextTheme} font-medium text-base`}>
            Profile setting
          </p>
          <p className="text-[#999898] font-normal text-[11px]">
            Customize profile
          </p>
        </div>
        <div
          className="flex flex-col border-t border-[#999898] p-2 cursor-pointer"
          onClick={onLoginHistoryClick}
        >
          <p className={`${toggleTextTheme} font-medium text-base`}>
            Login history
          </p>
          <p className="text-[#999898] font-normal text-[11px]">
            Click to view login history
          </p>
        </div>
      </div>
    </Frame>
  );
};

export default AccountSettings;
