import { BellIcon, DummyProfile } from "assets/images/svgs";
import useDisclosure from "hooks/useDisclosure";
import { useNavigate } from "react-router";
import AnchorTemporaryDrawer from "./Drawer";
import {
  useGetNotificationsQuery,
  useMarkAsReadMutation,
} from "../../redux/services/dashboardApi";
import { toast } from "sonner";
import { useState } from "react";

const NotificationWithProfile = ({ imgSrc }: { imgSrc: string }) => {
  const navigate = useNavigate();

  const {
    isOpen: openModal,
    open: onOpenModal,
    close: closeModal,
  } = useDisclosure();
  const { data: notifyData } = useGetNotificationsQuery();

  const [markAsRead, { isLoading: isLoadingRead }] = useMarkAsReadMutation();
  const [loadingID, setLoadingID] = useState<number>();
  const handleMarkAsRead = async (id: any) => {
    if (!id) {
      return;
    }
    setLoadingID(id);
    await markAsRead(id).unwrap();
    toast.success("Notification marked as read");
    setTimeout(() => {
      toast.dismiss();
    }, 1000);
  };

  return (
    <div className="flex gap-5 items-center cursor-pointer">
      <div
        className="border-[#999898] border-[.5px] rounded-[10px] w-[46px] h-[46px] relative flex flex-col items-center justify-center"
        onClick={onOpenModal}
      >
        <BellIcon />
        <span className="absolute top-1 right-1  transform border-[.4px] border-black rounded-full p-1.5 h-[20px] flex justify-center items-center font-bold text-[11px] text-black bg-[#FC1330]">
          {/* 3 */}
          {notifyData?.notifications?.length > 0 &&
          notifyData?.notifications?.length > 9
            ? "9+"
            : 0}
        </span>
      </div>
      <img
        onClick={() =>
          navigate("/settings?page=account-settings/settings-profile")
        }
        src={imgSrc}
        alt="profile"
        width={50}
        className="h-[50px] rounded-lg"
      />
      {/* drawer start */}
      <AnchorTemporaryDrawer
        open={openModal}
        onClose={closeModal}
        handleMarkAsRead={handleMarkAsRead}
        notifyData={notifyData}
        isLoading={isLoadingRead}
        loadingId={loadingID}
      />
      {/* drawer end */}
    </div>
  );
};

export default NotificationWithProfile;
