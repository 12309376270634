import { SearchField } from "component/Form";
import { Frame } from "component/Frame";
import { TitleText } from "component/Typography";
import { useStaffTable } from "./useStaffTable";
import { SelectField } from "component/Select";
import { outletTableHead, staffTableHead } from "./utils";
import { Table, TableBodyData } from "component/Table";
import useDisclosure from "hooks/useDisclosure";
import { useRef, useState } from "react";
import {
  useDownloadStaffMutation,
  useExportStaffMutation,
} from "../../redux/services/staffApi";
import {
  useDownloadOutletMutation,
  useExportOutletMutation,
} from "../../redux/services/outletApi";
import { toast } from "sonner";
import { useReactToPrint } from "react-to-print";
import { PageLoader } from "component/Loader/Loader";
import DashboardModal from "component/Modal/Modal";
import DeleteStaff from "./deleteStaff";
import { useSidebar } from "component/Sidebar/useSidebar";
import { ExcelIcon, PdfIcon, PrintIcon } from "assets/images/tsx";

const StaffTable = ({
  staffTableBody,
  loading,
  onOpenAddModal,
  isFetching,
  onEditChild,
  setIsEdit,
  search,
  handleSearchTable,
  isOutlet,
}: {
  staffTableBody: any[];
  loading?: boolean;
  isFetching?: boolean;
  onEditChild: (param: any) => void;
  onOpenAddModal: () => void;
  setIsEdit: (param: boolean) => void;
  search: string;
  handleSearchTable: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isOutlet?: boolean;
  // onTableClick: () => void;
}) => {
  const {
    isOpen: openDeleteModal,
    open: onOpenDeleteModal,
    close: closeDeleteModal,
  } = useDisclosure();

  const [childData, setChildData] = useState<any>();

  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  const handleMouseEnter = (icon: string) => {
    setHoveredIcon(icon);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const togglePDFIconTheme = isDarkTheme ? "stroke-white" : "";
  const togglePrintIconTheme = isDarkTheme ? " stroke-white" : "";
  const toggleExcelIconTheme = isDarkTheme ? "fill-white" : "";
  const hoverBgTheme = isDarkTheme
    ? "bg-[#222121] shadow-white-sm"
    : "bg-white shadow-xl";
  const hoverTextTheme = isDarkTheme ? "text-white" : "text-black";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const hoverClass = `text-xs rounded-lg text-center ${hoverTextTheme}`;

  const [downloadStaff] = useDownloadStaffMutation();
  const [exportStaff] = useExportStaffMutation();

  const [downloadOutlet] = useDownloadOutletMutation();
  const [exportOutlet] = useExportOutletMutation();

  const handleExport = async (param: string) => {
    toast.loading("Downloading...");
    try {
      let blob: Blob;
      let fileName: string;

      switch (true) {
        case isOutlet && param === "excel":
          blob = await downloadOutlet().unwrap();
          fileName = "outlet.xlsx";
          break;
        case isOutlet && param === "pdf":
          blob = await exportOutlet().unwrap();
          fileName = "outlet.pdf";
          break;
        case !isOutlet && param === "excel":
          blob = await downloadStaff().unwrap();
          fileName = "staff.xlsx";
          break;
        case !isOutlet && param === "pdf":
          blob = await exportStaff().unwrap();
          fileName = "staff.pdf";
          break;
        default:
          toast.dismiss();
          toast.error("Invalid export format");
          return;
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.dismiss();
      toast.success(`Exported successfully as ${fileName}`);
    } catch (error) {
      toast.dismiss();
      toast.error(`Export to ${param.toUpperCase()} failed`);
      console.error("Download failed", error);
    }
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Staff Data",
  });

  // const { handleSearchTable, search, sortVal, onSortChange } = useStaffTable();

  const topData = (
    <div className="flex justify-between items-center p-4">
      <div className="flex gap-8 items-center">
        {/* Excel Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("excel")}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleExport("excel")}
        >
          <ExcelIcon
            className={`cursor-pointer transition-all ${toggleExcelIconTheme} transition-all`}
          />
          <div
            className={`${hoverBgTheme} absolute w-[150px] transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 ${
              hoveredIcon === "excel" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Export to Excel</p>
          </div>
        </div>

        {/* Print Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("print")}
          onMouseLeave={handleMouseLeave}
          onClick={handlePrint}
        >
          <PrintIcon
            className={`cursor-pointer transition-all ${togglePrintIconTheme} transition-all`}
          />
          <div
            className={`${hoverBgTheme} absolute w-[150px] transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 ${
              hoveredIcon === "print" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Print</p>
          </div>
        </div>

        {/* PDF Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("pdf")}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleExport("pdf")}
        >
          <PdfIcon
            className={`cursor-pointer transition-all ${togglePDFIconTheme} transition-all`}
          />
          <div
            className={`${hoverBgTheme} absolute w-[150px] mt-3 transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 ${
              hoveredIcon === "pdf" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Export to PDF</p>
          </div>
        </div>
      </div>
      <div className="flex gap-5">
        <SearchField
          name="search"
          onChange={handleSearchTable}
          value={search}
        />
        {/* <SelectField
          showFilterIcon
          borderColor="#999898"
          padding="0 0 0 2.5rem"
          bgColor="#fff"
          placeholder="Sort by"
          value={sortVal}
          selectOptions={sortOptions}
          onChange={onSortChange}
        /> */}
      </div>
    </div>
  );

  const staffBody = (
    <div className="overflow-auto h-full">
      {/* <div className="overflow-auto h-[700px]"> */}
      {loading ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex flex-col justify-center items-center">
            <PageLoader />
          </div>
        </div>
      ) : staffTableBody?.length > 0 ? (
        staffTableBody.map((chi, idx) => {
          const {
            staff_name,
            id_number,
            position,
            pay_rate,
            outlet,
            address,
            contact_number,
            guardian_number,
            date_of_birth,
            outlets_name,
            number_of_staff,
            year_of_establishment,
          } = chi;
          return (
            <TableBodyData
              loading={isFetching}
              key={idx}
              one={
                isOutlet ? outlets_name || "--- ---" : staff_name || "--- ---"
              }
              two={!isOutlet ? id_number || "--- ---" : null}
              three={!isOutlet ? position || "--- ---" : null}
              four={!isOutlet ? pay_rate || "--- ---" : null}
              five={!isOutlet ? outlet?.outlets_name || "--- ---" : null}
              six={address || "--- ---"}
              seven={
                isOutlet
                  ? number_of_staff || "--- ---"
                  : contact_number || "--- ---"
              }
              eight={
                isOutlet
                  ? year_of_establishment || "--- ---"
                  : guardian_number || "--- ---"
              }
              nine={!isOutlet ? date_of_birth || "--- ---" : null}
              dataLength={isOutlet ? 5 : 10}
              onEdit={() => {
                onEditChild(chi);
                setIsEdit(true);
                onOpenAddModal();
              }}
              onDelete={() => {
                setChildData(chi);
                onOpenDeleteModal();
              }}
            />
          );
        })
      ) : (
       <p className={`font-medium transition-all ${toggleTextTheme} p-4`}>
          No {isOutlet ? "outlet" : "staff"} listed here...{" "}
          <span
            className="text-[#4A07A0] text-sm cursor-pointer"
            onClick={onOpenAddModal}
          >
            {isOutlet ? "Add outlet" : "Add staff"}
          </span>
        </p>
      )}
    </div>
  );

  return (
    <Frame
      rmPadding
      bg={toggledBgTheme}
      className="p-0 overflow-auto h-[650px] pb-10"
    >
      <div className="p-4 px-6 border-b border-[#999898] ">
        <TitleText value={isOutlet ? "All Outlets" : "All staffs"} />
      </div>
      {topData}
      <Table
        tableHead={isOutlet ? outletTableHead : staffTableHead}
        tableBody={staffBody}
        dataLength={isOutlet ? 5 : staffTableHead?.length}
      />
      {/* modal wrap start */}
      <DashboardModal
        open={openDeleteModal}
        onClose={closeDeleteModal}
        // width={"749px"}
      >
        <DeleteStaff
          data={childData}
          onCancel={closeDeleteModal}
          isRentTab={isOutlet}
        />
      </DashboardModal>
      {/* modal wrap end */}
    </Frame>
  );
};

export default StaffTable;
