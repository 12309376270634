import React, { useEffect, useState } from "react";
import { ScannedItem, invoiceGenerateTableHead } from "./utils";
import { Table, TableBodyData } from "component/Table";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { formatNumberWithCommas } from "helpers/helper";
import { useSidebar } from "component/Sidebar/useSidebar";
import { useDispatch } from "react-redux";
import { setAmount } from "../../redux/features/scannedItemsSlice";
import { number } from "yup";

const InvoiceGenerateTable = ({
  isLoading,
  disabled,
  invoiceData,
  setInvoiceData,
  isEdit,
}: {
  isLoading: boolean;
  disabled: boolean;
  invoiceData: ScannedItem[];
  setInvoiceData: React.Dispatch<React.SetStateAction<ScannedItem[]>>;
  isEdit?: boolean;
}) => {
  const handleDelete = (index: number) => {
    const newData = invoiceData.filter((_, idx) => idx !== index);
    setInvoiceData(newData);
  };

  const [quantities, setQuantities] = useState<{ [key: number]: number }>(
    invoiceData.reduce((acc, _, idx) => ({ ...acc, [idx]: 1 }), {})
  );

  const handleQuantityChange = (idx: number, newQuantity: number) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [idx]: newQuantity,
    }));

    const updatedData = invoiceData.map((item, index) => {
      if (index === idx) {
        const updatedAmount = newQuantity * Number(item.selling_price || 0);
        return {
          ...item,
          amount: updatedAmount,
        };
      }
      return item;
    });

    setInvoiceData(updatedData);
  };

  const totalAmount = invoiceData.reduce((acc, item, index) => {
    const sellingPrice = Number(item.selling_price) || 0;
    const quantity = quantities[index] || 0;
    const amount = quantity * sellingPrice;
    return acc + amount;
  }, 0);

  const [discount, setDiscount] = useState<string>("");

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || (Number(value) <= 100 && Number(value) >= 0)) {
      setDiscount(value);
    }
  };

  const { isDarkTheme } = useSidebar();
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const handleDiscountAmount = () => {
    const newAmountVal = (Number(discount) / 100) * totalAmount;
    const newDiscountVal = totalAmount - newAmountVal;
    return newDiscountVal;
  };

  const dispatch = useDispatch();

  const handleSaveAmount = () => {
    dispatch(
      setAmount({
        total_amount: totalAmount,
        discount_amount: handleDiscountAmount(),
      })
    );
  };

  const invoiceGerateTableBody = (
    <div className="overflow-auto p-4">
      {invoiceData?.map((chi, idx) => {
        const {
          product_name,
          product_description,
          selling_price = "0",
          currency,
          amount,
        } = chi;

        console.log(chi, "quantity value");
        return (
          <TableBodyData
            dataLength={6}
            key={idx}
            onePrev={product_name}
            one={product_description}
            oneClass="w-[200px]"
            quantity={true}
            quantityID={`quantity_${idx}`}
            quantityValue={quantities[idx] || 1}
            onQuantityChange={(e) =>
              handleQuantityChange(idx, Number(e.target.value) || 0)
            }
            two={
              <p>
                {isEdit
                  ? `${currency || "₦"}${formatNumberWithCommas(
                      Number(selling_price) || 0
                    )}`
                  : `${currency || "₦"}${formatNumberWithCommas(
                      Number(selling_price) || 0
                    )}`}
              </p>
            }
            three={`${currency || "₦"}${formatNumberWithCommas(amount || 0)}`}
            isNotEditable
            onDelete={() => handleDelete(idx)}
          />
        );
      })}

      <div className="flex flex-col gap-2 mt-5 justify-end">
        <div className="flex items-center gap-5 justify-end">
          <p className={`font-medium text-sm ${toggleTextTheme}`}>
            Total Amount (₦)
          </p>
          <input
            type="text"
            className={`${
              isDarkTheme ? "bg-[#222121] text-white" : ""
            } border p-2  outline-none font-bold rounded-lg text-center`}
            value={formatNumberWithCommas(totalAmount)}
            id="total_amount"
            disabled
            placeholder="Total Amount"
            name="total_amount"
          />
        </div>
        <div className="flex items-center gap-5 justify-end">
          <p className={`font-medium text-sm ${toggleTextTheme}`}>
            Discount (%)
          </p>
          <input
            type="number"
            className={`p-2 outline-none ${
              isDarkTheme
                ? "border-b-white text-white"
                : "border-b-black text-black"
            } bg-[transparent] placeholder:text-sm font-bold border-b text-center`}
            value={discount}
            name="discount"
            id="discount"
            onChange={handleDiscountChange}
            placeholder="Enter Discount"
          />
        </div>
        <div className="flex items-center gap-5 justify-end">
          <p className={`font-medium text-sm ${toggleTextTheme}`}>
            Discount Amount (₦)
          </p>
          <input
            type="text"
            className={`p-2 outline-none ${
              isDarkTheme
                ? "border-b-white text-white"
                : "border-b-black text-black"
            } bg-[transparent] font-bold border-b text-center`}
            value={formatNumberWithCommas(handleDiscountAmount())}
            id="discount_amount"
            disabled
            placeholder="Discount (₦)"
            name="discount_amount"
          />
        </div>
      </div>
      <Button
        onClick={handleSaveAmount}
        btnType={ButtonTypeEnum.Submit}
        label={isEdit ? "Update Invoice" : "Submit Invoice"}
        className="mt-10 w-[250px] self-end"
        loading={isLoading}
        disabled={disabled}
      />
    </div>
  );

  return (
    <div className="mt-10 pb-10 relative">
      <Table
        tableHead={invoiceGenerateTableHead}
        dataLength={6}
        tableBody={invoiceGerateTableBody}
      />
    </div>
  );
};

export default InvoiceGenerateTable;
