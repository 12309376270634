import { sidebar_items } from "./utils";
import { useSidebar } from "./useSidebar";
import { Logo } from "assets/images/svgs";
import { LogoutIcon, SettingsIcon } from "assets/images/tsx";
import { Switch } from "component/Button";
import DashboardModal from "component/Modal/Modal";
import Logout from "auth/Login/Logout";
import useDisclosure from "hooks/useDisclosure";

const Sidebar = () => {
  const {
    isDarkTheme,
    handleRoute,
    locationEquatesPath: isHighlighted,
    handleToggleTheme,
  } = useSidebar();

  const {
    isOpen: openModal,
    open: onOpenModal,
    close: closeModal,
  } = useDisclosure();

  const bottomItems = (
    <div className="flex flex-col  mt-[2rem]">
      <div
        className={`flex gap-[10px] items-center cursor-pointer p-[15px] border-0 rounded-[10px] ${
          isHighlighted("/settings") ? "bg-[#4A07A0] text-white" : ""
        }`}
        onClick={() => handleRoute("/settings?page=account-settings")}
      >
        <SettingsIcon fill={isHighlighted("/settings") ? "white" : ""} />
        <p>Settings</p>
      </div>
      <div
        className="flex gap-[10px] items-center cursor-pointer p-5"
        onClick={onOpenModal}
      >
        <LogoutIcon />
        <p className="text-[#FC1330]">Log out</p>
      </div>
    </div>
  );

  const toggledTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  return (
    <div
      className={`flex flex-col gap-5 w-[275px] h-screen p-5 ${toggledTheme} transition-all  relative `}
    >
      <div className="flex justify-center  ">
        <Logo className="w-[127.25px] flex" />
      </div>
      <div className=" flex flex-col overflow-scroll customScrollbar">
        {sidebar_items.map((chi, idx) => {
          const { label, icon: Icon, path } = chi;
          return (
            <div
              key={idx}
              className={`flex gap-[10px] items-center  ${
                isHighlighted(path) ? "bg-[#4A07A0] text-white" : ""
              } p-[15px] border-0 rounded-[10px] cursor-pointer`}
              onClick={() => handleRoute(path)}
            >
              <Icon fill={isHighlighted(path) ? "white" : ""} />
              <p>{label}</p>
            </div>
          );
        })}
      </div>
      {bottomItems}
      <div className="mt-auto">
        <Switch
          isChecked={Boolean(isDarkTheme)}
          toggleSwitch={handleToggleTheme}
          themeMode={isDarkTheme ? "Dark Mode" : "Light Mode"}
        />
      </div>

      {/* modal start */}
      <DashboardModal open={openModal} onClose={closeModal}>
        <Logout onCancel={closeModal} />
      </DashboardModal>
      {/* modal end */}
    </div>
  );
};

export default Sidebar;
