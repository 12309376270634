import Select from "react-select";
import { SelectType } from "./types";
import { useSelect } from "./useSelect";
import { FilterIcon } from "assets/images/svgs";

const SelectField = ({
  selectOptions,
  placeholder,
  value,
  onChange,
  bgColor,
  borderColor,
  padding,
  showFilterIcon,
  className,
  fontSize,
  name,
  isLoading,
}: SelectType) => {
  const { selectStyle } = useSelect({
    bgColor: bgColor,
    borderColor: borderColor,
    padding: padding,
    fontSize: fontSize,
  });
  return (
    <div className="relative grid place-items-center">
      <Select
        className={`text-[#6D6A6A] transition-all placeholder:text-[5px]  ${className}`}
        options={selectOptions}
        name={name}
        id={name}
        styles={selectStyle}
        placeholder={placeholder}
        value={value as any}
        onChange={onChange}
        getOptionLabel={(option) => option.label.toString()}
        getOptionValue={(option) => option.value.toString()}
        isLoading={isLoading}
      />
      {showFilterIcon ? <FilterIcon className="absolute left-0 ml-3" /> : null}
    </div>
  );
};

export default SelectField;
