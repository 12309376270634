import { baseApi } from "../../redux/baseApi";
import { InvoicesResponse } from "../../../@types/invoice";
import { QueryParams } from "../../../@types/dashboard";
import { generateQueryString } from "helpers/helper";
import { InvoiceItem } from "pages/Invoice/utils";

export const invoiceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createInvoice: builder.mutation({
      query: (payload) => ({
        url: "/invoice/create-invoice",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["invoices"],
    }),
    deleteInvoice: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `invoice/delete-invoice/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["invoices"],
    }),
    updateInvoice: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/invoice/update-invoice/${id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["invoices"],
    }),
    getInvoices: builder.query<InvoicesResponse, QueryParams>({
      query: (params) => ({
        url: `/invoice/get-all-invoice${generateQueryString({ ...params })}`,
        method: "GET",
      }),
      providesTags: ["invoices"],
      transformResponse: (response: { data: InvoicesResponse }) =>
        response.data,
    }),

    getInvoiceById: builder.query<InvoicesResponse, number>({
      query: (id) => ({
        url: `/invoice/get-invoice-by-id/${id}`,
        method: "GET",
      }),
      providesTags: ["invoices"],
      transformResponse: (response: { data: InvoicesResponse }) =>
        response.data,
    }),

    getScannedProduct: builder.query<InvoiceItem, number>({
      query: (barcode) => ({
        url: `products/barcode/${barcode}`,
        method: "GET",
      }),
      providesTags: ["invoices"],
      transformResponse: (response: { data: InvoiceItem }) => response.data,
    }),

    downloadInvoice: builder.mutation<Blob, void>({
      query: () => ({
        url: `invoice/export/excel`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
      // invalidatesTags: ["products"],
    }),
    exportInvoice: builder.mutation<Blob, void>({
      query: () => ({
        url: "invoice/export/pdf",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
      // invalidatesTags: ["products"],
    }),
  }),
});

export const {
  useCreateInvoiceMutation,
  useGetInvoicesQuery,
  useGetScannedProductQuery,
  useDeleteInvoiceMutation,
  useUpdateInvoiceMutation,
  useDownloadInvoiceMutation,
  useExportInvoiceMutation,
  useGetInvoiceByIdQuery,
} = invoiceApi;
