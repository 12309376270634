import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { InputField } from "component/Form";
import RadioInput from "component/Radio/Radio";
import React from "react";
import { useInviteUser } from "./useInviteUser";
import { SingleUser } from "../../../@types/auth";

const AddUserInvite = ({
  closeModal,
  user,
  editUser,
}: {
  closeModal: () => void;
  user?: SingleUser;
  editUser?: boolean;
}) => {
  const { formik, isLoading, isLoadingUpdate } = useInviteUser({
    closeModal,
    user: user,
    isUpdate: editUser,
  });
  const labelClass = "text-[14px] font-medium";
  const inputClass = `pl-3 ${
    editUser ? "cursor-not-allowed capitalize bg-//text-customGrayText" : ""
  }`;
  const roles = [
    { label: "Admin", value: "admin" },
    { label: "Users", value: "users" },
    { label: "Super Admin", value: "super-admin" },
  ];

  return (
    <div className="p-5 pb-10 px-10 bg-[#FAF9F6]">
      <p className="text-black font-medium text-2xl mt-4">
        {editUser ? "Update user" : "Add new user"}
      </p>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col gap-5 mt-14"
      >
        <InputField
          type="text"
          label="First name"
          name="first_name"
          onChange={formik.handleChange}
          value={formik.values.first_name}
          disabled={editUser ? true : false}
          placeholder="Enter first name"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Last name"
          name="last_name"
          onChange={formik.handleChange}
          value={formik.values.last_name}
          disabled={editUser ? true : false}
          placeholder="Enter last name"
          className={`${inputClass} ${
            editUser
              ? "cursor-not-allowed bg-[#d7d3d3cc] capitalize text-[#292828cc]"
              : ""
          }`}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Email"
          name="email"
          value={formik.values.email}
          disabled={editUser ? true : false}
          onChange={formik.handleChange}
          placeholder="Enter email"
          className={`${inputClass} ${
            editUser
              ? "cursor-not-allowed bg-[#d7d3d3cc]  text-[#292828cc]"
              : ""
          }`}
          labelClassName={labelClass}
        />
        <div className="mt-5">
          <p className="text-[#999898] font-medium text-sm bg-white p-2 border-b border-[#999898] rounded-t-lg">
            Click checkbox to assign role
          </p>
          <div className="flex flex-col gap-2 mt-5">
            {roles.map((chi) => (
              <RadioInput
                id={chi.label}
                key={chi.value}
                name="name"
                onChange={formik.handleChange}
                value={chi.value}
                label={chi.label}
                checked={formik.values.name === chi.value}
              />
            ))}
          </div>
        </div>
        <Button
          btnType={ButtonTypeEnum.Submit}
          label={editUser ? "Update" : "Save"}
          className="mt-14"
          loading={editUser ? isLoadingUpdate : isLoading}
          disabled={!formik.isValid || !formik.dirty}
        />
      </form>
    </div>
  );
};

export default AddUserInvite;
