import { Container } from "component/Container";
import { invoiceTabs } from "./utils";
import { useInvoice } from "./useInvoice";
import { Frame } from "component/Frame";

import { BigPlusIcon } from "assets/images/svgs";
import InvoiceTable from "./InvoiceTable";
import useDisclosure from "hooks/useDisclosure";
import DashboardModal from "component/Modal/Modal";
import AddInvoiceModal from "./AddInvoiceModal";
import InvoicePreviewModal from "./invoicePreview";
import { useGetInvoicesQuery } from "../../redux/services/invoiceApi";
import { useState } from "react";
import { useDebounce } from "helpers/helper";
import { Invoices } from "../../../@types/invoice";
import { Pagination } from "@mui/material";
import { useSidebar } from "component/Sidebar/useSidebar";

const Invoice = () => {
  const { handleInvoiceTabChange, isActiveTab, invoiceTab } = useInvoice();
  const {
    isOpen: openAddInvoiceModal,
    open: onOpenAddInvoiceModal,
    close: closeAddInvoiceModal,
  } = useDisclosure();

  const {
    isOpen: openInvoiceTemplateModal,
    open: onOpenInvoiceTemplateModal,
    close: closeInvoiceTemplateModal,
  } = useDisclosure();

  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<Invoices>();
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 1000);

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
  } = useGetInvoicesQuery({
    search: debouncedSearchVal,
    // search_by: productTab.value,
    filter_by: invoiceTab.value,
    page: page,
  });

  const lastPage = invoicesData?.invoice?.last_page;
  const invoices = invoicesData?.invoice?.data ?? [];

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const [invoiceID, setInvoiceID] = useState<number>();

  const invoiceTabsData = (
    <Frame
      rmPadding
      // bg="bg-[#FAF9F6]"
      bg={toggledBgTheme}
      className="p-2.5 flex  px-5 justify-between items-center border-0 rounded-[10px]"
    >
      {/* {invoiceTabs.map((chi, idx) => (
        <div key={idx}>
          <p
            className={`text-black w-[270px] text-center  cursor-pointer  ${isActiveTab(
              chi
            )}`}
            onClick={() => handleInvoiceTabChange(chi)}
          >
            {chi}
          </p>
        </div>
      ))} */}
      {invoiceTabs.map((chi, idx) => {
        const { label, value } = chi;
        return (
          <div key={idx} className="w-[500px]">
            <p
              className={`${toggleTextTheme} text-center cursor-pointer ${isActiveTab(
                value
              )}`}
              onClick={() => handleInvoiceTabChange(chi)}
            >
              {label}
            </p>
          </div>
        );
      })}
    </Frame>
  );
  const plusDisplay = (
    <div
      className="absolute right-12 mr-10 bottom-12 mb-14 cursor-pointer"
      onClick={onOpenAddInvoiceModal}
    >
      <BigPlusIcon />
    </div>
  );
  return (
    <Container>
      {invoiceTabsData}
      <div className="overflow-auto">
        {/* <InvoiceTable invoiceTableBody={[]} /> */}
        <InvoiceTable
          setInvoiceId={setInvoiceID}
          setIsEdit={setEdit}
          invoiceTableBody={invoices}
          loading={isLoadingInvoices}
          onOpenAddModal={onOpenAddInvoiceModal}
          onEditChild={setChildData}
          handleSearchTable={(e) => setSearchVal(e.target.value)}
          search={searchVal}
          isFetching={isFetching}
        />
      </div>
      {plusDisplay}

      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          count={lastPage}
          page={page}
          onChange={handleChange}
        />
      </div>
      {/* modal wrap start */}
      <DashboardModal
        open={openAddInvoiceModal}
        onClose={() => {
          closeAddInvoiceModal();
          setEdit(false);
        }}
        width={"70%"}
      >
        <AddInvoiceModal
          closeModal={() => {
            closeAddInvoiceModal();
            setEdit(false);
          }}
          invoiceID={invoiceID as number}
          isEdit={edit}
          data={childData as Invoices}
          onOpenTemplateModal={onOpenInvoiceTemplateModal}
        />
      </DashboardModal>
      {/* modal wrap end */}

      {/* ------- modal wrap start -----. */}
      <DashboardModal
        open={openInvoiceTemplateModal}
        onClose={closeInvoiceTemplateModal}
        width={"700px"}
        height={"85%"}
        borderRadius="0"
      >
        <InvoicePreviewModal />
      </DashboardModal>
      {/*-------  modal wrap end -----. */}
    </Container>
  );
};

export default Invoice;
