// export const SalesTabs = ["Sales", "Rent"];

export enum SalesTabEnum {
  SALES = "",
  RENT = "size",
}

export const SalesTabs = [
  { label: "Sales", value: SalesTabEnum.SALES },
  { label: "Rent", value: SalesTabEnum.RENT },
];

export const sortOptions = [
  {
    label: "Images",
    value: "images",
  },
  {
    label: "Product name",
    value: "product_name",
  },
  {
    label: "Description",
    value: "description",
  },
];

export const salesTableHead = [
  "Customer name",
  "Product name",
  "Description",
  "Quantity",
  "Size",
  "Date",
  "Price",
  // "Code number",
  "Entry officer",
  "Outlet of use",
  "Action",
];
export const rentTableHead = [
  "Customer name",
  "Product name",
  "Description",
  "Quantity",
  "Size",
  "Date",
  "Return Date",
  "Price",
  // "Code number",
  "Entry officer",
  "Outlet of use",
  "Action",
];

export const salesTableBody = [
  {
    customer_name: "Okolie Mathew",
    product_name: "lorem ipsum",
    description: "Bus tire",
    quantity: "1",
    size: "45",
    date: "4/6/2024",
    price: "₦50,000",
    code_number: "#3344556677",
    entry_officer: "Victor John",
    outlet_of_use: "Egbeda",
  },
];
