import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ScannedItem } from "pages/Invoice/utils";
import { RootStateType } from "redux/store";

export interface OtherItems {
  invoice_to: string;
  delivered_to: string;
  payment_method: string;
  invoice_date: string;
  invoice_no: string;
  entry_officer: string;
  customer: string;
  address: string;
  signature: string;
}
export interface Amount {
  total_amount: string | number;
  discount_amount: string | number;
}

interface ScannedItemsState {
  items: ScannedItem[];
  otherItems: OtherItems;
  amount: Amount;
}

const initialState: ScannedItemsState = {
  items: [],
  otherItems: {
    invoice_to: "",
    delivered_to: "",
    payment_method: "",
    invoice_date: "",
    invoice_no: "",
    entry_officer: "",
    customer: "",
    address: "",
    signature: "",
  },
  amount: {
    total_amount: "",
    discount_amount: "",
  },
};

const scannedItemsSlice = createSlice({
  name: "scannedItems",
  initialState,
  reducers: {
    addScannedInvoice: (state, action: PayloadAction<ScannedItem>) => {
      state.items.push(action.payload);
    },
    setScannedInvoice: (state, action: PayloadAction<ScannedItem[]>) => {
      state.items = action.payload;
    },
    clearScannedInvoice: (state) => {
      state.items = [];
    },
    setOtherScannedItems: (state, action: PayloadAction<OtherItems>) => {
      state.otherItems = action.payload;
    },
    setAmount: (state, action: PayloadAction<Amount>) => {
      state.amount = action.payload;
    },
  },
});

export const { setScannedInvoice } = scannedItemsSlice.actions;
export const { setOtherScannedItems } = scannedItemsSlice.actions;
export const { setAmount } = scannedItemsSlice.actions;

export const scannedSliceReducer = scannedItemsSlice.reducer;
export const selectScannedItems = (state: RootStateType) =>
  state.scannedItems.items;
export const selectOtherScannedItems = (state: RootStateType) =>
  state.scannedItems.otherItems;
export const selectAmount = (state: RootStateType) => state.scannedItems.amount;
