import { clearStorageItem } from "hooks/useLocalStorage";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { toast } from "sonner";
// import { useNavigate } from "react-router";
// import { handleUnauthorized } from "./unauthorized";
// import { resetAuth } from "../features/auth/authSlice";

const toastError = (errors: any) => {
  const errorKeys = Object.keys(errors);
  if (Array.isArray(errors[errorKeys[0]])) {
    const message = errors[errorKeys[0]][0];
    toast.error(message);
    return;
  } else {
    const message = errors[errorKeys[0]];
    toast.error(message);
  }
};

// console.log(process.env.REACT_APP_BACKEND_URL, "base url oo");

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers, { getState }) => {
    let accessToken: string = Cookies.get("token") || "";
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    headers.set("accept", "application/json");
    return headers;
  },
});

export const baseQueryInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  // console.log(result)

  if (result.error) {
    let res: any = result.error;
    if (res.status === 403) {
      //   api.dispatch(resetAuth());
      Cookies.remove("token");
      clearStorageItem();
      // window.location.href = "/login";
    }
    if (res.status === 401) {
      // if (res.data.status === "failed") {
        // api.dispatch(resetAuth());
        // api.dispatch(resetMissionPlan());
        // api.dispatch(resetMissionPlanPreview())
        Cookies.remove("token");
        clearStorageItem();
        // window.location.href = "/login";
        // let message = res.data.message;
        // toast.error(message);
      // }
    }

    // if (res.status === 401 || res.status === 403) {
    //   const message = res.data.message;

    //   // Handle unauthorized access
    //   handleUnauthorized(api.dispatch, message);

    //   return { error: { status: 401, data: "Unauthorized" } };
    // }

    // if (res.status === 401 || res.status === 403) {
    //   // Immediately clear tokens and local storage
    //   Cookies.remove("token");
    //   clearStorageItem();

    //   // Redirect to login
    //   let message = res.data.message;
    //   toast.error(message);
    //   window.location.href = "/login";
    //   return { error: { status: 401, data: "Unauthorized" } };
    // }

    if (res.status === 401) {
      // console.log(res?.data?.message, "response");
      let message = res.data.message;
      toast.error(message);
    }
    if (res.status === 404) {
      let message = res.data.message;
      toast.error(message);
    }
    if (res.status === 422) {
      let message = res.data.message;
      toast.error(message);
      if (res.data.errors) {
        toastError(res.data.errors);
      }
      // if (res.data.error) {
      // let message = res.data.message;
      // }
    }

    if (res.status === 409) {
      let message = res.data.message;
      toast.error(message);
    }

    if (res.status === 503) {
      let message = res.data.message || res.data.message;
      toast.error(message);
    }

    if (res.status === 500) {
      let message = res.data.message;
      toast.error(message);
    }

    if (res.status === 400) {
      let message = res.data.message;
      toast.error(message);
    }
  }

  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryInterceptor,
  endpoints: () => ({}),
  reducerPath: "baseApi",
  tagTypes: [
    "invitedUsers",
    "userDetails",
    "userPreferences",
    "invoices",
    "products",
    "sales",
    "rents",
    "purchases",
    "staffs",
    "credits",
    "debits",
    "notifications",
    "outlets",
    "credits",
    "debits",
  ],
});

console.log(baseApi, "base api");
