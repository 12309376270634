import React from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { InputField } from "component/Form";
import { FormikProps } from "formik";
import { useAppSelector } from "../../redux/store";
import { selectUser } from "../../redux/features/authSlice";
import { SingleUser } from "../../../@types/auth";
import { StaticImage } from "assets/images/svgs";
import { ProductsType } from "../../../@types/product";
import { useAddProduct } from "./useAddProduct";
import { formatDateToYYYYMMDD } from "helpers/helper";
import { useSidebar } from "component/Sidebar/useSidebar";
import { OptionType } from "pages/Dashboard/types";
import { SelectField } from "component/Select";
import { useGetAllPurchasesQuery } from "../../redux/services/productApi";

interface AddProductProps {
  closeModal: () => void;
  isEdit?: boolean;
  data: ProductsType;
}

const AddProduct: React.FC<AddProductProps> = ({
  closeModal,
  isEdit,
  data,
}) => {
  const { formik, isLoading, preview, handleFileChange, isLoadingUpdate } =
    useAddProduct({
      closeModal,
      isUpdate: isEdit,
      productId: data?.id,
      data,
    });

  const user = useAppSelector(selectUser);
  const labelClass = "text-[14px] font-medium";
  const inputClass = "pl-3";

  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "!bg-[#222121]" : "!bg-[#f0f0f0]";
  const toggledDateTheme = isDarkTheme ? "bg-[#222121]" : "";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const isButtonDisabled = !formik.isValid || !formik.dirty || !preview;
  const updateBtnDisabled = !formik.isValid || (!formik.dirty && !preview);

  const { data: productsData, isLoading: isLoadingProducts } =
    useGetAllPurchasesQuery();

  // console.log(productsData, "product data");
  const products = productsData?.purchase;

  const formatProducts = () => {
    const productList = (products as any[])?.map((chi) => {
      return {
        ...chi,
        label: chi?.product_name,
        value: chi?.product_name,
      };
    });
    return productList;
  };

  return (
    <form onSubmit={formik.handleSubmit} className="p-6 px-10">
      <div>
        <p className={`${toggleTextTheme} transition-all font-medium text-2xl`}>
          {isEdit ? "Update product" : "Add a new product"}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-5">
        {/* <InputField
          type="text"
          label="Product name"
          name="product_name"
          onChange={formik.handleChange}
          value={formik.values.product_name}
          placeholder="Enter name"
          className={inputClass}
          labelClassName={labelClass}
        /> */}
        <div className="flex flex-col gap-1 -ml-3 ">
          <label className={`${labelClass} ${toggleTextTheme} ml-3`}>
            Product Name
          </label>
          <SelectField
            value={formik.values.product as OptionType}
            selectOptions={formatProducts()}
            onChange={(e: any) => {
              console.log(e, "event");
              formik.setFieldValue("product", {
                label: e?.label,
                value: e?.value,
              });
            }}
            placeholder="Select Product.."
            className={`${inputClass} !w-full`}
            padding="6px"
            bgColor={isDarkTheme ? "#222121" : "white"}
            isLoading={isLoadingProducts}
          />
        </div>
        <InputField
          type="text"
          label="Product category"
          name="product_category"
          onChange={formik.handleChange}
          value={formik.values.product_category}
          placeholder="Enter category"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Entry officer"
          name="entry_officer"
          value={
            (isEdit && data?.entry_officer) ||
            `${(user as SingleUser)?.first_name} ${
              (user as SingleUser)?.last_name
            }`
          }
          disabled
          onChange={formik.handleChange}
          placeholder="Enter name"
          className={`${inputClass} cursor-not-allowed ${toggledBgTheme} !text-[#ccc]`}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Description"
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
          placeholder="Enter description"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="number"
          label="Quantity"
          name="quantity"
          onChange={formik.handleChange}
          value={formik.values.quantity}
          placeholder="Enter quantity"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="number"
          label="Size"
          name="size"
          onChange={formik.handleChange}
          value={formik.values.size}
          placeholder="Enter size"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="number"
          label="Cost price"
          name="cost_price"
          onChange={formik.handleChange}
          value={formik.values.cost_price}
          placeholder="Enter price"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="number"
          label="Selling price"
          name="selling_price"
          onChange={formik.handleChange}
          value={formik.values.selling_price}
          placeholder="Enter price"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Year of manufacturing"
          name="year_of_manufacturing"
          onChange={formik.handleChange}
          value={formik.values.year_of_manufacturing}
          placeholder="Enter year"
          className={inputClass}
          labelClassName={labelClass}
        />
        <div className="flex flex-col">
          <label className={`${labelClass} mb-1 ${toggleTextTheme}`}>
            Date
          </label>
          <Flatpickr
            value={formik.values.date}
            onChange={(selectedDates: Date[]) => {
              const [date] = selectedDates;
              formik.setFieldValue("date", formatDateToYYYYMMDD(date));
            }}
            placeholder="Enter invoice date"
            className={`${inputClass} ${toggleTextTheme}  rounded-lg border outline-none h-[50px] ${toggledDateTheme} `}
          />
        </div>
        <div>
          <p className={`ml-4 mb-3 font-medium ${toggleTextTheme} text-sm`}>
            Image
          </p>
          <input
            id="file"
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleFileChange}
          />
          <div>
            <img
              src={
                preview ||
                (isEdit && data?.product_image?.[0]?.path) ||
                StaticImage
              }
              alt="Product Preview"
              className="w-[150px] h-[150px] rounded-3xl"
            />
          </div>
          <label
            htmlFor="file"
            className="text-[#999898] font-medium text-xs mt-2 cursor-pointer"
          >
            Tap to upload a product image
          </label>
        </div>
      </div>
      <div className="flex justify-end mt-10">
        <Button
          btnType={ButtonTypeEnum.Submit}
          label={isEdit ? "Update" : "Save"}
          className="w-[284px]"
          disabled={isEdit ? updateBtnDisabled : isButtonDisabled}
          loading={isEdit ? isLoadingUpdate : isLoading}
        />
      </div>
    </form>
  );
};

export default AddProduct;
