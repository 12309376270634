import { useFormik } from "formik";
import {
  useCreateProductMutation,
  useUpdateProductMutation,
} from "../../redux/services/productApi";
import { toast } from "sonner";
import * as yup from "yup";
import { useState } from "react";
import { base64ToBlob } from "helpers/helper";
import { ProductsType } from "../../../@types/product";

export const useAddProduct = ({
  closeModal,
  isUpdate,
  productId,
  data,
}: {
  closeModal: () => void;
  isUpdate?: boolean;
  productId: number;
  data: ProductsType;
}) => {
  const validationSchema = yup.object().shape({
    // product_name: yup.string().required("Product name is required"),
    product: yup.object().shape({
      label: yup.string().required("Product name is required"),
      value:yup.string().required()
    }),
    product_category: yup.string().required("Product category is required"),
    // entry_officer_name: yup.string().required("Entry officer is required"),
    description: yup.string().required("Description is required"),
    quantity: yup
      .number()
      .required("Quantity is required")
      .positive("Quantity must be a positive number")
      .integer("Quantity must be an integer"),
    size: yup.string().required("Size is required"),
    cost_price: yup
      .number()
      .required("Cost price is required")
      .positive("Cost price must be a positive number"),
    selling_price: yup
      .number()
      .required("Selling price is required")
      .positive("Selling price must be a positive number"),
    year_of_manufacturing: yup
      .number()
      .required("Year of manufacturing is required")
      .integer("Year of manufacturing must be an integer")
      .min(1900, "Year of manufacturing must be after 1900")
      .max(
        new Date().getFullYear(),
        "Year of manufacturing cannot be in the future"
      ),
    date: yup.string().required("Date is required"),
  });

  const formValues = {
    // product_name: (isUpdate && data?.product_name) || "",
    product_category: (isUpdate && data?.product_category) || "",
    // entry_officer: (isUpdate && data?.entry_officer) || "",
    description: (isUpdate && data?.product_description) || "",
    quantity: (isUpdate && data?.quantity) || "",
    size: (isUpdate && data?.size) || "",
    cost_price: (isUpdate && data?.cost_price) || "",
    selling_price: (isUpdate && data?.selling_price) || "",
    year_of_manufacturing: (isUpdate && data?.year_of_manufacturing) || "",
    date: (isUpdate && data?.date) || "",
    product: (isUpdate && {
      label: data?.product?.product_name,
      value: data?.product?.id,
    }) || {
      label: "Select Product name...",
      value: "",
    },
    // entry_officer_name: (isUpdate && data?.entry_officer) || "",
  };

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | undefined>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const [createProduct, { isLoading }] = useCreateProductMutation();

  const [updateProduct, { isLoading: isLoadingUpdate }] =
    useUpdateProductMutation();

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("product_name", (formik.values.product as any).label);
    // formData.append("product_name", formik.values.product_name);
    formData.append("product_category", formik.values.product_category);
    // formData.append("entry_officer", formik.values.entry_officer_name);
    formData.append("product_description", formik.values.description);
    formData.append("quantity", String(formik.values.quantity));
    formData.append("size", String(formik.values.size));
    formData.append("cost_price", formik.values.cost_price);
    formData.append("selling_price", formik.values.selling_price);
    formData.append(
      "year_of_manufacturing",
      formik.values.year_of_manufacturing
    );
    formData.append("date", formik.values.date);

    if (selectedFile) {
      formData.append("product_image", selectedFile);
    } else if (preview) {
      const imageBlob = base64ToBlob(preview);
      formData.append("product_image", imageBlob, "product_image.jpg");
    }

    if (isUpdate) {
      const payload = { payload: formData, id: productId };
      await updateProduct(payload)
        .unwrap()
        .then(() => {
          toast.success("Product Updated Successfully");
          new Promise(() => {
            setTimeout(() => {
              toast.dismiss();
              closeModal();
            }, 1000);
          });
        });
    } else {
      await createProduct(formData)
        .unwrap()
        .then(() => {
          toast.success("Product Created Successfully");
          new Promise(() => {
            setTimeout(() => {
              toast.dismiss();
              closeModal();
            }, 1000);
          });
        });
    }
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return {
    formik,
    isLoading,
    isLoadingUpdate,
    selectedFile,
    preview,
    handleFileChange,
  };
};
