import { DeleteIcon, EditIcon } from "assets/images/svgs";
import { TableBodyType, TableType } from "./types";
import { useTable } from "./useTable";
import Skeleton from "react-loading-skeleton";
import { Pagination } from "@mui/material";
import { useSidebar } from "component/Sidebar/useSidebar";

const Table = ({
  tableHead,
  tableBody,
  className,
  dataLength,
  thClass,
  theadClass,
}: TableType) => {
  const { handleGetLastArray } = useTable();
  const gridTemplateColumns = `repeat(${dataLength || "13"}, 1fr)`;

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#191919]" : "bg-[#F5F5F5]";
  const tableHeadData = (
    <thead
      className={`${theadClass} grid grid-cols-13   border-b border-[#D9D9D9CC] transition-all  ${toggledBgTheme} p-4 justify-between items-center`}
      style={{ display: "grid", gridTemplateColumns }}
    >
      {tableHead.map((chi, idx) => (
        <th
          key={idx}
          className={`text-xs text-left font-medium text-[#767676] ${thClass} ${
            handleGetLastArray(tableHead, chi) ? "text-right" : ""
          } `}
        >
          {chi}
        </th>
      ))}
    </thead>
  );

  return (
    <table className="w-full overflow-hidden">
      {tableHeadData}
      {tableBody}
    </table>
  );
};
export default Table;

export const TableBodyData = ({
  image,
  onePrev,
  onePrevClass,
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  qrImage,
  dataLength,
  // className,
  quantity,
  quantityID,
  quantityValue,
  onQuantityChange,
  twoClass,
  threeClass,
  isNotEditable,
  isNotDeletable,
  fourClass,
  trClass,
  oneClass,
  imgAlt,
  imgSrc,
  eightClass,
  nineClass,
  tenClass,
  eleven,
  elevenClass,
  twelve,
  twelveClass,
  loading,
  onTableClick,
  onEdit,
  onDelete,
  returnText,
}: TableBodyType) => {
  const gridTemplateColumns = `repeat(${dataLength || "13"}, 1fr)`;

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";

  return (
    <tr
      className={`text-left border-b relative ${toggledBgTheme} transition-all border-[#D9D9D9CC] items-center p-4 text-xs font-medium text-[#767676] ${trClass}`}
      style={{ display: "grid", gridTemplateColumns }}
      onClick={onTableClick}
    >
      {image && (
        <td className="flex justify-start">
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={50}
              height={30}
            />
          ) : (
            <img src={imgSrc} alt={imgAlt} className="w-[40px] h-[30px]" />
          )}
        </td>
      )}
      {/* {image && <td className="flex justify-start">{image}</td>} */}
      {onePrev ? (
        <td className={onePrevClass}>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            onePrev
          )}
        </td>
      ) : null}
      {one ? (
        <td className={oneClass}>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            one
          )}
        </td>
      ) : null}
      {quantity ? (
        <td className="flex justify-start -ml-7">
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            <input
              type="number"
              className={`${toggledBgTheme} border p-2 outline-none rounded-lg`}
              value={quantityValue}
              id={quantityID}
              name={quantityID}
              onChange={onQuantityChange}
            />
          )}
        </td>
      ) : null}
      {two ? (
        <td className={twoClass}>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            two
          )}
        </td>
      ) : null}
      {three ? (
        <td className={threeClass}>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            three
          )}
        </td>
      ) : null}
      {four ? (
        <td className={fourClass}>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            four
          )}
        </td>
      ) : null}
      {five ? (
        <td>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            five
          )}
        </td>
      ) : null}
      {six ? (
        <td>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            six
          )}
        </td>
      ) : null}
      {returnText ? (
        <td>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            returnText
          )}
        </td>
      ) : null}
      {seven ? (
        <td>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            seven
          )}
        </td>
      ) : null}
      {qrImage && (
        <td className="flex justify-start">
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={50}
              height={30}
            />
          ) : (
            qrImage
          )}
        </td>
      )}
      {eight && (
        <td className={eightClass}>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            eight
          )}
        </td>
      )}
      {nine && (
        <td className={nineClass}>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            nine
          )}
        </td>
      )}
      {ten && (
        <td className={tenClass}>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            ten
          )}
        </td>
      )}
      {eleven && (
        <td className={elevenClass}>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            eleven
          )}
        </td>
      )}
      {twelve && (
        <td className={twelveClass}>
          {loading ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={100}
              height={20}
            />
          ) : (
            twelve
          )}
        </td>
      )}
      <td className="flex gap-2 items-center absolute right-0 mr-3 justify-end">
        {!isNotEditable ? (
          <EditIcon
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onEdit && onEdit();
            }}
          />
        ) : null}
        {!isNotDeletable ? (
          <DeleteIcon
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onDelete && onDelete();
            }}
          />
        ) : null}
      </td>
    </tr>
  );
};
