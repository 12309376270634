import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootStateType } from "../../redux/store";

interface ThemeState {
  isDarkTheme: boolean;
}

const initialState: ThemeState = {
  isDarkTheme: false,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setDarkTheme: (state, action: PayloadAction<{ isDarkTheme: boolean }>) => {
      state.isDarkTheme = action.payload.isDarkTheme;
    },
  },
});

export const { setDarkTheme } = themeSlice.actions;

export const themeSliceReducer = themeSlice.reducer;
export const selectTheme = (state: RootStateType) => state.theme.isDarkTheme;
