import { generateQueryString } from "helpers/helper";
import { baseApi } from "../../redux/baseApi";
import { QueryParams } from "../../../@types/dashboard";
import { SalesOutletResponse, SalesResponse } from "../../../@types/sales";

export const creditApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createCredit: builder.mutation({
      query: (payload) => ({
        url: "credit/create-credit",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["credits"],
    }),

    getCredits: builder.query<any, QueryParams>({
      query: (params) => ({
        url: `/credit/get-credit${generateQueryString({ ...params })}`,
        method: "GET",
      }),
      providesTags: ["credits"],
      transformResponse: (response: any) => response,
    }),

    updateCredit: builder.mutation<void, { id: number; payload: any }>({
      query: ({ id, payload }) => ({
        url: `credit/update-credit/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["credits"],
    }),

    deleteCredit: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `credit/delete-credit/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["credits"],
    }),

    downloadCredits: builder.mutation<Blob, void>({
      query: () => ({
        url: "/export/excel",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),

    exportCredits: builder.mutation<Blob, void>({
      query: () => ({
        url: "sales/export/pdf",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useCreateCreditMutation,
  useGetCreditsQuery,
  useUpdateCreditMutation,
  useDeleteCreditMutation,
  useDownloadCreditsMutation,
  useExportCreditsMutation,
} = creditApi;
