import { useRef, useState } from "react";

export const maskEmail = (email: string): string => {
  const [username, domain] = email.split("@");
  if (!username || !domain) return email;

  const maskedUsername = username.slice(0, 4) + "****";
  return `${maskedUsername}@${domain}`;
};

export const base64ToBlob = (base64String: string) => {
  const byteString = atob(base64String.split(",")[1]);
  const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const formatDate = (isoDateString: string): string => {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}/${month}/${day}`;
};

/**
 * Formats a number or numeric string with commas as thousands separators.
 * @param {string | number} value - The value to format.
 * @returns {string} The formatted number as a string with commas.
 */
export const formatNumberWithCommas = (value: string | number): string => {
  const number = typeof value === "string" ? parseInt(value, 10) : value;
  return number?.toLocaleString();
};

export function formatDateToYYYYMMDD(date: Date): string {
  return date.toISOString().split("T")[0];
}

type ItemDataType = {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
};
export const formatDropdown = (items: ItemDataType[]) => {
  const data = items.map((chi) => {
    return {
      ...chi,
      // label: chi?.username,
      label: `${chi?.first_name} ${chi?.last_name}`,
      value: chi?.id,
    };
  });
  return data;
};

export const formatCurrency = (amount: number): string => {
  return `₦${amount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

export type UpdateSVGDimensionsProps = {
  svgString: string;
  newWidth: number;
  newHeight: number;
};

export function updateSVGDimensions({
  svgString,
  newWidth,
  newHeight,
}: UpdateSVGDimensionsProps): string {
  const widthRegex = /(<svg[^>]*\s)width="[^"]+"/;
  const heightRegex = /(<svg[^>]*\s)height="[^"]+"/;

  let updatedSVG = svgString.replace(widthRegex, `$1width="${newWidth}"`);
  updatedSVG = updatedSVG.replace(heightRegex, `$1height="${newHeight}"`);

  return updatedSVG;
}

export const printElement = (elementRef: React.RefObject<HTMLElement>) => {
  if (elementRef.current) {
    const originalContents = document.body.innerHTML;
    const printContents = elementRef.current.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Refresh the page after printing
  }
};


export const generateQueryString = (params: { [key: string]: any }): string => {
  const queryParams = [];

  for (const key in params) {
    const value = params[key];
    if (value || value === 0 || value === false) {
      queryParams.push(`${key}=${value}`);
    }
  }

  if (queryParams.length > 0) {
    return `?${queryParams.join("&")}`;
  } else {
    return "";
  }
};

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const setDebounced = (newValue: T) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setDebouncedValue(newValue);
    }, delay);
  };

  setDebounced(value);

  return debouncedValue;
}
